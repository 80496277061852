.rdw-option-wrapper {
    border: 0;
}
.public-DraftStyleDefault-ltr{
    margin: 6px 0;
}
.rdw-editor-toolbar {
    border: 0;
}

.rdw-editor-wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
}

.customIcon {
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

.rdw-suggestion-dropdown {
    z-index: 1000;
    position: absolute;
}

.rdw-suggestion-option:hover {
    cursor: pointer;
}



@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html {
    font-size: 14px;
}

body > iframe {
    pointer-events: none;
}

/* Hide the eye icon */
::-ms-clear,
::-ms-reveal {
  display: none;
}

.paginate-ctn {
    display: flex;
}
.round-effect {
    /* color: #000; */
    cursor: pointer;

    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 9px;
    height: 40px;
    width: 40px;
    margin: 5px;
    text-decoration: none;
}

.round-effect:hover {
    text-decoration: none;
    /* color: #000; */
    text-decoration: underline;
    text-decoration-color: rgba(0,0,255);
    text-underline-position: below;
    text-decoration-thickness: 3px;
}
.round-effect:hover a {
    text-decoration: none;
    /* color: #000; */
}

.active {
    /* color: #000; */
    text-decoration: underline;
    text-decoration-color: rgba(0,0,255);
    text-underline-position: below;
    text-decoration-thickness: 3px;
}
a:link {
    text-decoration: none;
    /* color: #000000; */
}
.file-container {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px dashed #8d8d8d;
}
.file-container input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    left: 0;
}

.file-team-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
}
.file-team-container input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    left: 0;
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: rgba(30, 64, 175, 1);
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

.profileImage input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.sidebar-right {
    -webkit-transform: translate3d(0%, 0px, 0px);
    transform: translate3d(0%, 0px, 0px);
    visibility: visible;
    transition: all 1s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}
.sidebar-right.closed {
    -webkit-transform: translate3d(500px, 0px, 0px);
    transform: translate3d(500px, 0px, 0px);
    visibility: visible;
}

.action-hide {
    visibility: hidden;
}

.action-menu:hover .action-hide {
    visibility: visible;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.fa-xs {
    font-size: 0.625rem;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border: none;
    background: #e2e8f0;
    border-radius: 100vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e2e8f0;
}
.react-datepicker-popper {
    z-index: 11 !important;
}
.content-container {
    width: calc(100% - 5rem);
    margin-left: 5rem;
    min-height: calc(100vh - 78px);
  }

.react-datepicker__day--today{
    font-weight: bold !important;
    background: #DEE5EF !important;
    opacity: 1;
}
/*.react-datepicker__navigation{
    display: none !important;
}*/
.w-Select {
    width: calc(100% - 2rem) !important;
}

/* .feeScheduleTable thead th{
    width: 15%;
}
.feeScheduleTable thead th:last-child{
    width: 40%;
}

.feeScheduleTable tbody td:last-child{
    text-align: center;
} */
.householdBilling  .material-field input{
    margin: 0;
    border-bottom: 1px solid rgba(75, 85, 99, var(--tw-border-opacity));
}
.active-tab:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: -2px;
    height: 2px;
    background-color: rgba(0,0,255);
}

.active-tab-white:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: -2px;
    height: 2px;
    background-color: rgb(255, 255, 255);
}

.active-tab-red:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: -2px;
    height: 2px;
    background-color: rgba(223, 48, 48, 1);
}

.reativeH {
    height: calc(100% - 42px);
}

.line {
    height: 1px;
    width: 100%;
    color: #999999;
    background-color: #999999;
    position: relative;
}

.line:before, .line:after {
    position: absolute;
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    top: -2px;
    background-color: #999999;
    color: #999999;
}
.line:before {
    left: 0;
}
.line:after  {
    right: 0;
}

/* tippy tooltip */
.tippy-tooltip {
    padding: 0 !important;
}

.tippy-popper{
    max-width: 600px;
}

/* team color input radius */

.team-color::-webkit-color-swatch, .team-color::-webkit-color-swatch-wrapper {
    border-radius: 4px;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

/* Change Password min-height */

.change-password-height {
    min-height: 535px;
}

/* Reset Mfa min-height */

.reset-mfa-height {
    min-height: 235px;
}

/* Open Request & Upcoming Clients Review */

.open-request {
    max-width: 17rem;
}

/* Member Card Right Entity max-width */

.member-right-mx {
    max-width: 9rem;
}

/* Open Request Workflows Min Width */

.open-request-workflows {
    min-width: 460px;
}

/* Compliance Alert min-width */

.compliance-alert-min {
    min-width: 16rem;
}

/* Fee Schedules Structure Max Height */

.max-h-feeSchedules {
    max-height: calc(100vh - 530px);
}

.max-h-strategySetup {
    max-height: calc(100vh - 350px);
}

/* Last child no border */

.last-child-noborder:last-child {
    border: none;
}

/* Inflow & Outflow bar colors */

.inflow-bar-bgcolor {
    background-color: #0F56B387;
}

.outflow-bar-bgcolor {
    background-color: #0F56B3;
}

/* max height for permission table */

.max-height-permission {
    max-height: calc(100vh - 350px);
}
.max-height-table{
    max-height: calc(100vh - 350px);
}
/* max height for transaction for review table */

.max-height-transaction-for-review {
    max-height: calc(100vh - 190px);
}

/* Strategy Transfer Button Height */

 .strategy-transfer-height {
    height: calc(100% - 48px);
} 

.payoutBlock-receivables-height {
    max-height: calc(100vh - 390px);
} 

/* Checkbox styling */

input[type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    accent-color:#0F56B3;
}

.react-pdf__Page__canvas,
.react-pdf__Page__svg {
    margin: auto;
}

.react-pdf__Page__textContent {
    width: 100% !important;
}
.pet_label_background{
    background-color: #ff6c10;
}

/* lazy loading classes */

.loading{
    filter: blur(10px);
}

.loaded{
    filter: blur(0px);
    transition: filter 0.5s linear;
}
.custom-box-shadow:hover{ 
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.25), 0px 4px 6px rgba(0, 0, 0, 0.25), 4px 0px 6px rgba(0, 0, 0, 0.25);
}
.max-text-width-influencer {
    max-width: 260px;
}

.resizer {
    position: absolute;
    top: 0;
    right: 0;
    cursor: ew-resize;
    user-select: none;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 10;
    height: 100%;
}

.resizer div {
    height: 100%;
}

.resizer:hover div,
.resizing div {
    border-right: 2px solid rgb(209, 213, 219);
}

.text-white-title {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
  
.text-white-subtitle {
    --tw-text-opacity: 1;
    color: rgba(226, 226, 226, var(--tw-text-opacity));
}
  
.text-white-tab-inactive{
    --tw-text-opacity: 1;
    color: rgba(181, 181, 181, var(--tw-text-opacity));
}

.sortable-column:hover {
    background-color: #ededed;
}

.ew-resize {
    cursor: ew-resize;
}

.custom-width-transition {
    transition: width ease-in-out 0.5s;
}

.list-item {
    opacity: 0;
    position: absolute;
    transition: opacity 0.25s;
}

.list-item.appear {
    opacity: 1;
    position: relative;
}

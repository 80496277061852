.material-field > .input-field {
    position: relative;
    /* margin-right: 3rem; */
}

.material-field .material-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
    outline: none;
    height: 3rem;
    font-size: 16px;
    margin-bottom: 8px;
    padding-left: 0px;
    box-shadow: none;
    box-sizing: border-box;
    overflow: visible;
    line-height: 1.15;
    outline-offset: 0px;
    font-weight: normal;
}

.material-field .select-autocomplete {
    /* margin-left: 3rem; */
    /* width: calc(100% - 3rem); */
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
    outline: none;
    height: 3rem;
    /* width: 100%; */
    font-size: 16px;
    margin: 0 0 8px 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s, border 0.3s;
    transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

.material-field .autocomplete-list {
    margin-left: 3rem;
    width: calc(100% - 3rem);
    padding: 0.5rem;
    list-style: none;
}

.material-field .select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid lightgray;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    display: block;
    user-select: none;
    z-index: 1;
    border-radius: 0;
    width: 100%;
}

.input-field .dropdown-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    list-style-type: none;
    background-color: white;
    margin: 0;
    display: none;
    min-width: 100px;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    transform-origin: 0 0;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
    height: auto;
}

.dropdown-content .selected {
    background-color: rgba(0, 0, 0, 0.09);
}

.dropdown-content .disabled {
    color: rgba(0, 0, 0, 0.3);
}

.dropdown-content li {
    list-style-type: none;
    cursor: pointer;
    min-height: 50px;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
}

.dropdown-content li:hover {
    background-color: rgba(0, 0, 0, 0.09);
}

.dropdown-content li.disabled > span {
    color: rgba(0, 0, 0, 0.3);
    background-color: transparent;
}

.dropdown-content li > span {
    /* font-size: 16px; */
    /* color: #26a69a; */
    display: block;
    line-height: 22px;
    padding: 14px 6px;
}

.input-field .select-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 0;
    fill: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    height: 24px;
    width: 24px;
}

.input-field .material-select {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
    display: none;
    text-transform: none;
    line-height: 1.15;
    margin: 0;
}

.material-field .input-icon {
    width: 100%;
}

.material-field label {
    position: absolute;
    top: 0;
    font-size: 1rem;
    cursor: text;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(10px);
}

.material-field .material-input:focus {
    outline: none;
    outline-offset: 0px;
}
.material-field .renderAutocompletelabel {
    left: 0rem;
}
.material-field label.active {
    transform: translateY(-4px) scale(0.8);
    transform-origin: 0 0;
    outline: none;
    text-decoration: none;
    left: 0;
}

.material-field label.renderAutocompletelabel.active {
    transform: translateY(-10px) scale(0.8);
}

.material-field label::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.material-field .icon {
    position: absolute;
    font-size: 2rem;
    transition: color 0.2s;
    top: 0.5rem;
}

.material-field .select-active {
    display: block;
    width: 100%;
    left: 0;
    top: 50px;
    min-height: 150px;
    transform-origin: 0px 0px 0px;
    opacity: 1;
    transform: scaleX(1) scaleY(1);
}

.material-field .autocomplete-active {
    display: block;
    /* width: 150%; */
    left: 0px;
    top: 50px;
    max-height: 350px;
    transform-origin: 0px 0px;
    opacity: 1;
    transform: scaleX(1) scaleY(1);
}

.prospect-field .calc-width {
    width: auto;
    border-bottom: none;
    margin: 0;
}

.prospect-field input {
    border-bottom: none;
}

.prospect-field {
    border-bottom: 1px solid lightgray;
}
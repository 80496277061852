.editor:empty:before {
    content: attr(data-placeholder);
    color: gray;
}

.mention-item:hover {
    background-color: #b4d5ff;
}

.mention-item {
    padding: 5px;
    border-radius: 5px;
}

.editor {
    min-height: 40px !important;
}

.text-feedback {
    width: 30rem;
}

@media screen and (max-width: 1450px) {
    .text-feedback {
        width: 24rem;
    }
}

@media screen and (max-width: 1280px) {
    .text-feedback {
        width: 20rem;
    }
}

@media screen and (max-width: 1150px) {
    .text-feedback {
        width: 16rem;
    }
}

@media screen and (max-width: 1000px) {
    .text-feedback {
        width: 10rem;
    }
}
